import { useEffect } from 'react';
import TermsAndConditionsModule from '../../modules/terms-and-conditions/TermsAndConditionsModule';

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.documentElement.scrollTo(0, 0);
  }, []);

  return <TermsAndConditionsModule />;
};

export default TermsAndConditions;
