import styled from 'styled-components';

const Root = styled.div`
    height: 1px;
    width: 100%;
    background-color: #DEDEDE;
`;

const Hr = () => <Root />;

export default Hr;
