import styled from 'styled-components';
import Container from '../../components/container/Container';
import Text from '../../components/text/Text';
import Padded from '../../components/padded/Padded';
import Flex from '../../components/flex/Flex';
import Certificate from './Certificate';

const CERTIFICATES = [
  {
    title: 'AWS',
    image: '/static/certificates/aws.svg'
  },
  {
    title: 'HIPPA',
    image: '/static/certificates/hipaa.svg'
  },
  {
    title: 'GDPR',
    image: '/static/certificates/gdpr.svg'
  },
  {
    title: 'FERPA',
    image: '/static/certificates/ferpa.svg'
  }
];

const Root = styled.section`
    padding: 8rem 0;

    @media only screen and (max-width: 768px) {
      padding: 6.4rem 0;
      background-color: #F7F7F7;
    }
`;

const Ilustration = styled.img`
    width: 46rem;
    max-width: 100%;

    @media only screen and (max-width: 768px) {
      display: none;
    }
`;

const CertificatesWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.6rem;
    
    @media only screen and (max-width: 768px) {
      grid-template-columns: 100%;
    }
`;

const Secure = () => (
  <Root id="certificates">
    <Container>
      <header>
        <Text
          as="h3"
          color="black"
          textTransform="uppercase"
          fontSize="xl"
          align="center"
          fontWeight="bold"
          media={{
            768: {
              align: 'left'
            }
          }}
        >
          სერთიფიკატები და ლიცენზიები
        </Text>
        <Padded
          top
          size="2.4rem"
        >
          <Text
            lineHeight="1.6"
            align="center"
            media={{
              768: {
                align: 'left'
              }
            }}
          >
            მოკლე აღწერა თუ რამდენად დაცული და უსაფრთხო პლატფორმაა ლეგალ
            <br />
            დოკუმენტებზე სამუშაოდ. ყველა სერთიფიკატი და მომხმარებლის დაცვა
          </Text>
        </Padded>
      </header>

      <Padded
        top
        size="4rem"
      >
        <Flex alignItems="center">
          <Padded
            right
            size="2.4rem"
            style={{ flex: 1 }}
            media={{
              768: {
                right: {
                  size: '0'
                }
              }
            }}
          >
            <CertificatesWrapper>
              {CERTIFICATES.map(({ title, image }) => (
                <Certificate
                  key={title}
                  title={title}
                  image={image}
                />
              ))}
            </CertificatesWrapper>
          </Padded>
          <Ilustration
            src="/static/ilustrations/license-agreement.png"
            alt="License Agreement"
          />
        </Flex>
      </Padded>
    </Container>
  </Root>
);

export default Secure;
