import PropTypes from 'prop-types';
import styled from 'styled-components';
import Padded from '../../components/padded/Padded';
import Text from '../../components/text/Text';
import Button from '../../components/button/Button';

const Root = styled.div`
  text-align: center;
  padding: 3rem 1.5rem;
  border-right: 1px solid #dedede;

  &:first-child {
    border-left: 1px solid #dedede;
  }

  @media only screen and (max-width: 768px) {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;

    padding: 3rem 1.5rem;
  }
`;

const PricingPlan = (props) => {
  const { name, cta, price, features, ...rest } = props;

  return (
    <Root {...rest}>
      <Text
        fontSize="xl"
        color="black"
        textTransform="uppercase"
        fontWeight="bold"
      >
        {name}
      </Text>

      <Padded
        top
        size="1.5rem"
      >
        <Text
          fontSize="sm"
          lineHeight="1.6"
        >
          მოკლედ პაკეტის შესახებ ვისთვის არის და რატომ უნდა აირჩიოს
          მომხმარებელმა
        </Text>
      </Padded>

      <Padded
        as="ul"
        top
        size="3rem"
      >
        {features.map((feature) => (
          <Padded
            as="li"
            top
            size="1rem"
          >
            <Text as="span">
              <Text
                as="span"
                color="primary"
              >
                {feature[0]}
              </Text>
              {' '}
              {feature[1]}
            </Text>
          </Padded>
        ))}
      </Padded>

      <Padded
        top
        size="4rem"
      >
        <Text
          color="black"
          fontSize="xxxl"
        >
          {price}
        </Text>
        <Text
          color="black"
          textTransform="uppercase"
        >
          ლარი / თვე
        </Text>

        <Padded
          top
          size="4rem"
        >
          <Button
            fullWidth
            as="a"
            href="http://app.ecosign.ge/authentication/try-ecosign"
          >
            {cta}
          </Button>
        </Padded>
      </Padded>
    </Root>
  );
};

PricingPlan.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  cta: PropTypes.string.isRequired,
};

export default PricingPlan;
