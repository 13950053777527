import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Container from '../../components/container/Container';
import Logo from '../../components/logo/Logo';
import Flex from '../../components/flex/Flex';
import Button from '../../components/button/Button';
import Padded from '../../components/padded/Padded';
import NavbarMenu from './NavbarMenu';

const Root = styled.div`
    box-shadow: 0px 1px 4px rgba(39, 39, 39, 0.08);
    background-color: #fff;
    z-index: 3;
    position: relative;
`;

const Header = styled.header`
    height: 7rem;
    display: flex;
    align-items: center;
`;

const LogoWrapper = styled.div`
    width: 28rem;
    min-width: 28rem;
`;

const AuthButtonsWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Navbar = () => (
  <Root>
    <Container>
      <Header>
        <LogoWrapper>
          <Link to="/">
            <Logo />
          </Link>
        </LogoWrapper>

        <Flex
          flexBasis="100%"
          justifyContent="flex-end"
          alignItems="center"
        >

          <NavbarMenu />

          <AuthButtonsWrapper>
            <Padded
              left
              size="2.4rem"
            >
              <Button
                size="small"
                variant="outlined"
                as="a"
                href="http://app.ecosign.ge/"
              >
                ავტორიზაცია
              </Button>
            </Padded>

            <Padded
              left
              size="2rem"
            >
              <Button
                size="small"
                as="a"
                href="http://app.ecosign.ge/"
              >
                ჩაერთე უფასოდ
              </Button>
            </Padded>

          </AuthButtonsWrapper>
        </Flex>
      </Header>
    </Container>
  </Root>
);

export default Navbar;
