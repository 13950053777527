import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.div`
  width: 1140px;
  margin: 0 auto;

  @media only screen and (max-width: 1280px) {
    width: 1024px;
  }

  @media only screen and (max-width: 1080px) {
    width: 705px;
  }

  @media only screen and (max-width: 768px) {
    width: 600px;
  }

  @media only screen and (max-width: 640px) {
    width: 400px;
  }

  @media only screen and (max-width: 440px) {
    width: 320px;
  }
`;

const Container = ({ children }) => <Root>{children}</Root>;

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;
