import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Flex = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-basis: ${({ flexBasis }) => flexBasis};

  ${({ media }) => {
    if (media && media[768]) {
      return css`
        @media only screen and (max-width: 768px) {
          flex-direction: ${({ media: { 768: { flexDirection } } }) => flexDirection && flexDirection};
          justify-content: ${({ media: { 768: { justifyContent } } }) => justifyContent && justifyContent};
        }
      `;
    }

    return css``;
  }}
`;

Flex.defaultProps = {
  justifyContent: 'normal',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'normal',
  flexBasis: 'auto',
};

Flex.propTypes = {
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
  justifyContent: PropTypes.string,
  flexBasis: PropTypes.string
};

export default Flex;
