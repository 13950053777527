import { Navigate } from 'react-router-dom';
import MainLayout from './layouts/main/MainLayout';

import HomePage from './pages/home/Home';
import PrivacyPolicyPage from './pages/privacy-policy/PrivacyPolicy';
import TermsAndConditionsPage from './pages/terms-and-conditions/TermsAndConditions';

const routes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <HomePage />
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicyPage />
      },
      {
        path: 'terms-and-conditions',
        element: <TermsAndConditionsPage />
      },
      {
        path: '*',
        element: (
          <Navigate
            to="/"
            replace
          />
        )
      }
    ]
  },
];

export default routes;
