/* eslint-disable react/no-unescaped-entities */
import styled from 'styled-components';
import Container from '../../components/container/Container';
import Padded from '../../components/padded/Padded';
import Text from '../../components/text/Text';

const Root = styled.main`
    padding: 6.5rem 0;
`;

const TermsAndConditionsModule = () => (
  <Root>
    <Container>
      <Padded
        left
        right
        size="20rem"
      >
        <Text
          as="h3"
          fontSize="xxl"
          textTransform="uppercase"
          lineHeight="1.6"
          fontWeight="bold"
          color="black"
          align="center"
        >
          კონფიდენციალურობა
        </Text>
        <Padded
          top
          size="4rem"
        >
          <Text
            color="black"
            lineHeight="1.6"
          >
            წინამდებარე დოკუმენტი ატარებს სამართლებრივ ძალას. თქვენს მიერ „ვეთანხმები“-ს მონიშვნა გულისხმობს რომ გაეცანით დოკუმენტს, ადასტურებთ მას და აცნობიერებთ რომ თქვენს და Ecosign.ge შორის ურთიერთობა დარეგულირდება ამ დოკუმენტის თანახმად.
            <br />
            <br />
            1. ტერმინთა განმარტება
            <br />
            <br />
            1.1. Ecosign.ge - ტერმინის ქვეშ, წინამდებარე დოკუმენტის მიზნებისათვის, იგულისხმება იურიდიული პირი [შპს “ენჯითი როქით სოლუშენს"], რომელიც ფლობს ვებ-საიტს https://ecosign.ge. აქვს ლეგალური უფლებამოსილება მართოს პლატფორმა და განახორციელოს დოკუმენტებზე ელექტრონული ხელმოწერის დადასტურების მომსახურება.
            <br />
            1.2. პლატფორმა - პროგრამული უზრუნველყოფის და ვებ-საიტის ერთობლიობა, რომელიც უზრუნველყოფს მომხმარებლებს შორის ინფორმაციის და დოკუმენტების გაცვლას, ელექტრონულ ხელმოწერას, სხვა თანმხლებ სერვისებს.
            <br />
            1.3. სერვისი/სერვისები - ის ფუნქციები, ოპერაციები, ტრანზაქციები, რომელთა განხორციელების საშუალებას პლატფორმა აძლევს მომხმარებელს. სერვისების ჩამონათვალი და სარგებლობის ფარგლები, განისაზღვრება მომხმარებლის მიერ არჩეული სატარიფო გეგმის მიხედვით.
            <br />
            1.4. კლიენტი - ფიზიკური ან იურიდიული პირი, სხვა ნებისმიერი სახის ორგანიზაციული წარმონაქმნი, ადმინისტრაციული ორგანო ან სხვა სამართლებრივი სუბიექტი, რომელიც ქმნის ანგარიშს პლატფორმაზე.
            <br />
            1.5. ვიზიტორი - ფიზიკური ან იურიდიული პირი, სხვა ნებისმიერი სახის ორგანიზაციული წარმონაქმნი, ადმინისტრაციული ორგანო ან სხვა სამართლებრივი სუბიექტი, რომელიც სარგებლობს პლატფორმით [მათ შორის აწერს ხელს დოკუმენტს] ანგარიშის შექმნის გარეშე.
            <br />
            1.6. მომხმარებელი - გულისხმობს კლიენტსაც და ვიზიტორსაც.
            <br />
            1.7. ინიციატორი - კლიენტი ან ქვე-მომხმარებელი [იხ. პუნქტი 4.9] რომელიც ახორციელებს დოკუმენტზე ხელმოწერის პროცესის ინიცირებას, მათ შორის განსაზღვრავს ხელმომწერ პირებს და ხელმოწერის სხვა პროცედურულ საკითხებს.
            <br />
            1.8. მესამე პირი - ფიზიკური ან იურიდიული პირი, სხვა ნებისმიერი სახის ორგანიზაციული წარმონაქმნი, ადმინისტრაციული ორგანო ან სხვა სამართლებრივი სუბიექტი, გარდა მომხმარებლისა და Ecosign.ge.
            <br />
            1.9. ანგარიში - პლატფორმაზე მომხმარებლის რეგისტრაციის შემდეგ, მომხმარებლის პირადი სივრცე, რომელიც გულისხმობს რომ მომხმარებელი პერმანენტულად სარგებლობს პლატფორმით და იყენებს ანგარიშთან დაკავშირებულ სერვისებს.
            <br />
            1.10. ელექტრონული ხელმოწერა - გულისხმობს მომხმარებლის მიერ პლატფორმაზე ნების გამოვლენას და ქმედებათა ერთობლიობას, რის შედეგადაც იგი გამოხატავს სამართლებრივი ძალის მქონე ნებას დოკუმენტთან დაკავშირებით/ხელს აწერს დოკუმენტს.
            <br />
            1.11. კვალიფიციური ელექტრონული ხელმოწერა - განვითარებული ელექტრონული ხელმოწერა, რომელიც კვალიფიციური ელექტრონული ხელმოწერის შექმნის საშუალების გამოყენებით, კვალიფიციური ელექტრონული ხელმოწერის სერტიფიკატის საფუძველზეა შესრულებული. პლატფორმით სარგებლობის მიზნებისათვის კვალიფიციური ელექტრონული ხელმოწერა გულისხმობს ნების გამოვლენას/ხელმოწერას სპეციალური მოწყობილობის და ელექტრონული პირადობის მოწმობის/ელექტრონული შტამპის გამოყენებით.
            <br />
            1.12. დოკუმენტი - ნებისმიერი სახის ტექსტური ინფორმაცია, რომელიც ფაილის სახით აიტვირთა ან შეიქმნა ან გაიგზავნა ან მიღებული იქნა მომხმარებლის მიერ.
            <br />
            1.13. ტრანზაქცია - ქმედებათა ერთობლიობა [ატვირთვა, გაგზავნა, მიღება, ხელმოწერა და ა.შ.] რომლის შედეგადაც ხდება დოკუმენტზე ელექტრონული ხელმოწერა.
            <br />
            1.14. სატარიფო გეგმა - კონკრეტული სერვისების ერთობლიობა, რომლის განსაზღვრული პერიოდით სარგებლობისათვის, მომხმარებელი იხდის გადასახდელს. სატარიფო გეგმების შესახებ ინფორმაცია მოცემულია ვებ-საიტზე https://ecosign.ge.
            <br />
            1.15. ელექტრონული ფოსტა - მომხმარებელთან ან ქვე-მომხმარებელთან დაკავშირებული და მის მფლობელობაში არსებული, მოქმედი ელექტრონული ფოსტის მისამართი. მომხმარებელი არ არის უფლებამოსილი შეცვალოს ანგარიშის შექმნის დროს გამოყენებული/ანგარიშთან ასოცირებული ელექტრონული ფოსტის მისამართი.
            <br />
            1.16. ტელეფონის ნომერი - მომხმარებელთან ქვე-მომხმარებელთან დაკავშირებული და მის მფლობელობაში არსებული, მოქმედი, ფიჭური კავშირისათვის შესაბამისი ტელეფონის ნომერი.
            <br />
            1.17. მტკიცებულების რეპორტი - თითოეულ ხელმოწერილ დოკუმენტს Ecosign.ge ანიჭებს უნიკალურ ინფორმაციათა ერთობლიობას, რომელიც სულ მცირე შეიცავს: (1) ხელმომწერი პირის ელექტრონული ფოსტის მისამართს (2) ხელმომწერი პირის სახელს, გვარს (3) მოწყობილობის IP მისამართს, რომლის მეშვეობითაც ისარგებლა ხელმომწერმა (4) ხელმოწერის დროს და თარიღს (5) ხელმოწერილი დოკუმენტის ჰეშ კოდს (6) ხელმომწერი პირის ტელეფონის ნომერს [ასეთის არსებობის შემთხვევაში] (7) ტექნიკური მოწყობილობის [პერსონალური კომპიუტერი, სმარტ - ტელეფონი და ა.შ.] იდენტიფიკატორს და ბრაუზერის ვერსიას რომლის გამოყენებითაც ხდება ხელმოწერა (8) Ecosign.ge ციფრული ელექტრონული სერტიფიკატს.
            <br />
            1.18. ციფრული ელექტრონული სერტიფიკატი - Ecosign.ge მიერ თითოეული დოკუმენტისათვის მინიჭებული ელექტრონული, უნიკალური სერტიფიკატი.
            <br />
            1.19. ხელშეკრულება - წინამდებარე დოკუმენტი, “კონფიდენციალურობის პოლიტიკა” და ყველა სხვა დოკუმენტის ერთობლიობა რომელიც არეგულირებს მოხმარებელს და Ecosign.ge ურთიერთობას.
            <br />
            1.20. არა - ავტორიზირებული სარგებლობა - ანგარიშის გამოყენება/ანგარიშთან დაკავშირებული სერვისებით სარგებლობა იმ პირის მიერ რომელსაც ამის უფლება არ მინიჭებია მომხმარებლის მიერ.
            <br />
            <br />
            2. იურიდიული ძალა, მტკიცებულებად გამოყენება
            <br />
            <br />
            2.1. ელექტრონული ხელმოწერის სამართლებრივი საფუძვლების შესახებ მითითებულია https://ecosign.ge/legal
            <br />
            2.2. თითოეულ ხელმოწერილ დოკუმენტთან დაკავშირებით, მომხმარებელს რომელიც ახორციელებს დოკუმენტის ხელმოწერის ინიცირებას, საშუალება აქვს მიიღოს/ჩამოტვირთოს/შეინახოს/გააზიაროს მტკიცებულების რეპორტი.
            <br />
            2.3. დოკუმენტზე თანდართული ციფრული ელექტრონული სერტიფიკატი მინიმუმ ძალაშია [ვალიდურია] 8 [რვა] წლის განმავლობაში, ამასთან თუ მომხმარებელი სარგებლობს პლატფორმით ისე, რომ წყვეტის პერიოდი არც ერთ შემთხვევაში არ გადააჭარბებს 180 დღეს, ციფრული ელექტრონული სერტიფიკატი პერიოდულად განახლდება [გაიზრდება მოქმედების ვადა] Ecosign.ge მიერ.
            <br />
            2.4. თითოეულ ხელმომწერ პირს ხელმოწერამდე სავალდებულო წესით ეცნობება “შეტყობინება სამართლებრივი ძალის შესახებ”. ამასთან, ინიციატორს უფლება აქვს გააუქმოს აღნიშნული შეტყობინება, ასეთ შემთხევაში, შესაძლო ნეგატიურ შედეგებზე სრული პასუხისმგებლობა ეკისრება მომხმარებელს/ინიციატორს.
            <br />
            2.5. Ecosign.ge ყველა დოკუმენტს ინახავს დაშიფრულ ფორმატში და არა აქვს მის შინაარსზე წვდომა.
            <br />
            2.6. ნებისმიერი დოკუმენტი ავტომატურად კონვერტირდება PDF ფორმატში. თითოეული ხელმოწერილი დოკუმენტისთვის გენერირდება ჰეშ კოდი, და ხელმოწერილ დოკუმენტს ენიჭება ecosign.ge ციფრული ელექტრონული სერტიფიკატი გრძელვადიანი ვალიდურობით. ყოველივე ეს უზრუნველყოფს იმის გარანტიას, რომ ხელმოწერილი დოკუმენტის შინაარსი შეცვლას არ ექვემდებარება, დოკუმენტში უმნიშვნელო ცვლილებაც კი იწვევს ჰეშ კოდის ცვლილებას და შესაბამისად ხელმოწერის დარღვევას.
            <br />
            <br />
            3. ზოგადი
            <br />
            <br />
            3.1. Ecosign.ge თითოეულ მომხმარებელს მიაწვდის იმ მოცულობის სერვისებს რასაც ითვალისწინებს მის მიერ შერჩეული სატარიფო გეგმა. აღნიშნული მომსახურება ენიჭება თითოეულ მომხმარებელს ინდივიდუალურად და მას არა აქვს სერვისებით სარგებლობის უფლება მთლიანად ან ნაწილობრივ გადასცეს სხვა პირებს.
            3.2. ყოველი ანგარიშისათვის, შერჩეული სატარიფო გეგმის მიხედვით, განსაზღვრულია მეხსიერების კონკრეტული მოცულობა, რომელის ფარგლებშიც მომხმარებელს საშუალება აქვს ატვირთოს, მიიღოს ან/და შეინახოს დოკუმენტები.
            3.3. მომხმარებელი პასუხისმგებელია მის მიერ ატვირთული, გაზიარებული, გავრცელებულ ინფორმაციაზე ან/და პლატფორმით სარგებლობისას გამოყენებულ მონაცემებზე.
            3.4. მომხმარებელი იძლევა გარანტიას, რომ პლატფორმით სარგებლობისას მის მიერ პერსონალური და სხვა სახის ინფორმაციის ან მონაცემების დამუშავებით და გამოყენებით არ ირღვევა მონაცემთა დაცვის მოქმედი კანონები, წესები და რეგულაციები. დეტალურად იხილეთ “კონფიდენციალურობის პოლიტიკა”
            <br />
            <br />
            4. პლატფორმაზე რეგისტრაცია/ანგარიშის შექმნა
            <br />
            <br />
            4.1. თუ პირს სურს მომხმარებლად რეგისტრაცია და შესაბამისად ანგარიშის შექმნა, იგი გამოხატავს შესაბამის ნებას პლატფორმაზე, და ელექტრონული ფოსტით და პაროლით ქმნის ანგარიშს.
            <br />
            4.2. ანგარიშის შექმნის შედეგად პირს ენიჭება კლიენტის სტატუსი.
            <br />
            4.3. მომხმარებელმა პლატფორმაზე იდენტიფიკაცია/რეგისტრაცია უნდა გაიროს უნიკალური და მხოლოდ მის მფლობელობაში/მასთან ასოცირებული ელექტრონული ფოსტით და სახელით/ გვარით ან საფირმო სახელწოდებით. არ შეიძლება ორ ან მეტ მომხმარებელს ჰქონდეს იდენტური ელექტრონული ფოსტა ან/და საფირმო სახელწოდება.
            <br />
            4.4. სახელი/გვარი - თუ მომხმარებელი წარმოადგენს ფიზიკურ პირს, იგი ვალდებულია მიუთითოს მის პირადობის დამადასტურებელ ოფიციალურ დოკუმენტში მითითებული სახელი და გვარი. ამავე დოკუმენტში მითითებული იდენტური ანბანური სიმბოლოების გამოყენებით [იგულისხმება როგორც ქართული, ისე ლათინური სიმბოლოების გამოყენება].
            <br />
            4.5. საფირმო სახელწოდება - თუ მომხმარებელი წარმოადგენს იურიდიულ პირს, იგი ვალდებულია თავის სახელწოდებაში მიუთითოს ის საფირმო სახელწოდება რომლითაც რეგისტრირებულია შესაბამის სახელმწიფო თუ სხვა ოფიციალურ რეესტრში, დოკუმენტში მითითებული იდენტური ანბანური სიმბოლოების გამოყენებით [იგულისხმება როგორც ქართული, ისე ლათინური სიმბოლოების გამოყენება].
            <br />
            4.6. სხვა სახელი - თუ მომხმარებელს წარმოადგენს ორგანიზაციული წარმონაქმნი, რომელიც არ არის იურიდიული პირი, იგი ვალდებულია თავის სახელწოდებაში მიუთითოს ის საფირმო სახელწოდება რომელიც მას მითითებული აქვს მის სადამფუძნებლო დოკუმენტებში [ყველა ცვლილების გათვალისწინებით].
            <br />
            4.7. პლატფორმა საშუალებას აძლებს მომხმარებელს მიუთითოს სხვა დამატებითი საიდენტიფიკაციო მონაცემები [პირადი ნომერი, საიდენტიფიკაციო ნომერი] და ა.შ.
            <br />
            4.8. თუ ანგარიში იქმნება იურიდიული პირის ან სხვა სახის ორგანიზაციული წარმონაქმნის მიერ, იგულისხმება რომ ნებას გამოხატავს/ანგარიშს ქმნის უფლებამოსილი პირი.
            <br />
            4.9. თუ ანგარიში იქმნება იურიდიული პირის ან სხვა სახის ორგანიზაციული წარმონაქმნის მიერ, ანგარიშის მფლობელ პირს უფლება აქვს ანგარიშს დაამატოს ქვე-მომხმარებელი და მიანიჭოს მათ სხვადასხვა ფუნქციები [მაგალითად დოკუმენტის დადასტურების /ე.წ. ვიზირების/ ან ხელმოწერის, დოკუმენტის ხელმოსაწერად ინიცირების უფლება]. ქვე-მომხმარებლის მიერ პლატფორმით სარგებლობისას, ქვე-მომხმარებელზე სრულად ვრცელდება ხელშეკრულებით განსაზღვრული ვალდებულებები, ხოლო მის ქმედებებზე პასუხისმგებელია ანგარიშის მფლობელი მომხმარებელი.
            <br />
            4.10. ქვე-მომხმარებელს შესაძლებელია ასევე მიენიჭოს შემდეგი ფუნქციები: ადმინისტრირების პარამეტრების კონფიგურაცია, წვდომის მიცემა და ავტორიზაცია, განსხვავებული ან დამატებითი სერვისების მოთხოვნა, სერვისებით სარგებლობის და შესრულების ჩანაწერების მომზადება, შაბლონების მართვა. ქვე-მომხმარებლის მიერ გამოვლენილი ნება წარმოშობს უფლება - მოვალეობებს მომხმარებლისათვის.
            <br />
            <br />
            წინამდებარე ხელშეკრულების ინტერპრეტაციისათვის მნიშვნელობა არ ენიჭება.
          </Text>
        </Padded>

      </Padded>
    </Container>
  </Root>
);

export default TermsAndConditionsModule;
