import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';
import Text from '../../components/text/Text';

const menu = [
  {
    title: 'დახმარება',
    path: '/#contact',
  },
  {
    title: 'სერთიფიკატები',
    path: '/#certificates',
  },
  {
    title: 'პაკეტები',
    path: '/#pricing',
  },
];

const Root = styled.ul`
    display: flex;

    > li:not(:first-child) {
        margin-left: 1.6rem;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
`;

const Menu = (props) => (
  <Root {...props}>
    {menu.map(({ title, path }) => (
      <li key={title}>
        <Text
          as={Link}
          to={path}
          textTransform="uppercase"
        >
          {title}
        </Text>
      </li>
    ))}
  </Root>
);

export default Menu;
