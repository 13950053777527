import styled from 'styled-components';
import Container from '../../components/container/Container';
import Flex from '../../components/flex/Flex';
import Padded from '../../components/padded/Padded';
import Text from '../../components/text/Text';

const categories = [
  {
    value: '2,800,721',
    title: 'ხელმოწერა '
  },
  {
    value: '23,050',
    title: 'მომხმარებელი '
  },
  {
    value: '8,500',
    title: 'კომპანია'
  },
  {
    value: '20',
    title: 'ქვეყანა მსოფლიოში'
  }
];

const Root = styled.section`
    padding: 8rem 0;

    @media only screen and (max-width: 768px) {
      padding: 6.4rem 0;
    }

`;

const Ilustration = styled.img`
    width: 46rem;
    max-width: 100%;
`;

const Categories = styled.ul`
    flex: 1;
    padding-left: 2.4rem;

    li {
        border-bottom: 1px solid #DEDEDE;
        padding: 2.4rem 2rem;

        @media only screen and (max-width: 768px) {
          padding: 2.4rem 0;
        }
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
    }
`;

const Statistics = () => (
  <Root>
    <Container>
      <header>
        <Text
          as="h3"
          color="black"
          textTransform="uppercase"
          fontSize="xl"
          align="center"
          fontWeight="bold"
          media={{
            768: {
              align: 'left'
            }
          }}
        >
          ჩვენი შედეგები
        </Text>
        <Padded
          top
          size="2.4rem"
        >
          <Text
            lineHeight="1.6"
            align="center"
            media={{
              768: {
                align: 'left'
              }
            }}
          >
            გახადეთ თქვენი და თქვენი კომპანიის ყოველდღიური საქმიანობა ეფექტური
            <br />
            ეკოსაინის ციფრული პლატფორმის დახმარებით
          </Text>
        </Padded>
      </header>

      <Padded
        top
        size="4rem"
        media={{
          768: {
            top: {
              size: '0'
            }
          }
        }}
      >
        <Flex
          alignItems="center"
          media={{
            768: {
              flexDirection: 'column'
            }
          }}
        >
          <Ilustration
            src="/static/ilustrations/newsletter.png"
            alt="Newsletter"
          />

          <Categories>
            {categories.map(({ title, value }) => (
              <li
                alignItems="center"
                key={title}
              >
                <Flex alignItems="center">
                  <Text
                    fontSize="xxxxl"
                    color="primary"
                    media={{
                      768: {
                        fontSize: 'xxxl'
                      }
                    }}
                  >
                    {value}
                  </Text>

                  <Padded
                    left
                    size="2.4rem"
                    media={{
                      768: {
                        left: {
                          size: '1rem'
                        }
                      }
                    }}
                  >
                    <Text
                      fontSize="xl"
                      textTransform="uppercase"
                      color="black"
                    >
                      {title}
                    </Text>
                  </Padded>
                </Flex>
              </li>
            )) }
          </Categories>
        </Flex>
      </Padded>
    </Container>
  </Root>
);

export default Statistics;
