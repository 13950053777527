import styled from 'styled-components';
import { ReactComponent as FaceboodIcon } from '../../icons/facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../icons/linkedin.svg';
import { ReactComponent as TwiiterLinearIcon } from '../../icons/twitter.svg';

const Root = styled.ul`
    > li:not(:first-child) {
        margin-top: 1.5rem;

    @media only screen and (max-width: 768px) {
      margin-top: 0;
      margin-left: 1.5rem;
    }
    }

    @media only screen and (max-width: 768px) {
      margin-top: 4rem;
      display: flex;
    }
`;

const IconWrapper = styled.div`
    width: 5.6rem;
    height: 5.6rem;
    background-color: #4B5855;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;

    &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    }
    
    svg {
        color: #fff;
    }
`;

const FooterSocialMedia = () => (
  <Root>
    <li>
      <a>
        <IconWrapper>
          <FaceboodIcon />
        </IconWrapper>
      </a>
    </li>

    <li>
      <a>
        <IconWrapper>
          <LinkedinIcon />
        </IconWrapper>
      </a>
    </li>

    <li>
      <a>
        <IconWrapper>
          <TwiiterLinearIcon />
        </IconWrapper>
      </a>
    </li>
  </Root>
);

export default FooterSocialMedia;
