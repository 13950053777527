import styled from 'styled-components';
import PropTypes from 'prop-types';
import Text from '../../components/text/Text';

const Root = styled.li`
  &:not(:first-child) {
    margin-top: 1rem;

    @media only screen and (max-width: 768px) {
      margin-top: 0;
    }
  }

  button {
    background: none;
    border: none;
    width: 31rem;
    padding: 1rem 1.5rem;
    border-radius: 20px;
    transition: 150ms;

    @media only screen and (max-width: 768px) {
      width: auto;
      white-space: nowrap;
      padding: 1.7rem 2rem;
      background-color: #DEDEDE;
      p {
        color: #434343;
      }
    }
  }

  &:hover {
    button {
      background-color: #1bb290;
      p {
        color: #fff;
      }
    }
  }

  ${({ active }) => active
    && `
        button {
            background-color: #1BB290;
            p {
                color: #fff;
            }
        }
    `}
`;

const FeatureTab = (props) => {
  const { label, value, description, image, current, onClick, ...rest } = props;

  return (
    <Root
      {...rest}
      active={current}
    >
      <button
        type="button"
        onClick={() => onClick(value)}
      >
        <Text
          align="left"
          textTransform="uppercase"
        >
          {label}
        </Text>
      </button>
    </Root>
  );
};

FeatureTab.propTypes = {
  label: PropTypes.string,
  value: PropTypes.PropTypes.oneOf([
    'createDocument',
    'createSignature',
    'menageFolders',
    'monitoring',
    'videoCalls',
    'templates',
    'other',
  ]),
  description: PropTypes.string,
  image: PropTypes.string,
  current: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FeatureTab;
