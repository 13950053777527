import { useRoutes } from 'react-router-dom';
import routes from './routes';

import 'nprogress/nprogress.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

// Global Styles
import FontGlobalStyle from './styles/FontStyle';
import GlobalStyle from './styles/GlobalStyle';

const App = () => {
  const content = useRoutes(routes);

  return (
    <div>
      <FontGlobalStyle />
      <GlobalStyle />

      {content}
    </div>
  );
};

export default App;
