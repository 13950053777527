/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Text = styled.p`
  margin: 0;
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ theme, color }) => theme.main.colors[color] || color};
  font-size: ${({ theme, fontSize }) => theme.main.sizes.fonts[fontSize]};
  font-weight: ${({ theme, fontWeight }) => theme.main.fontWeights[fontWeight]};
  transition: 150ms;

  &[href]:hover {
    color: #18B290;
  }
  
  ${({ align }) => align && `
    text-align: ${align};
  `}
  
  ${({ textTransform }) => (textTransform === 'uppercase'
    ? `
    font-feature-settings: 'case' on;
    text-transform: uppercase;
    ` : `
    transform: ${textTransform};
  `)}

  ${({ ellipsis }) => ellipsis
    && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}


  ${({ media }) => {
    if (media && media[768]) {
      return css`
        @media only screen and (max-width: 768px) {
          text-align: ${({ media: { 768: { align } } }) => align && align};
          font-weight: ${({ theme, media: { 768: { fontWeight } } }) => fontWeight && theme.main.fontWeights[fontWeight]}};
          font-size: ${({ theme, media: { 768: { fontSize } } }) => fontSize && theme.main.sizes.fonts[fontSize]};};
        }
      `;
    }

    return css``;
  }}


`;

Text.defaultProps = {
  color: 'gray',
  ellipsis: false,
  fontSize: 'md',
  fontWeight: 'regular',
  align: null,
  lineHeight: 'normal',
  textTransform: null,
  // TODO : This is temporary
  theme: {
    main: {
      colors: {
        primary: '#18B290',
        black: '#272727',
        white: '#fff',
        gray: '#707070'
      },
      sizes: {
        fonts: {
          xs: '1.2rem',
          sm: '1.4rem',
          md: '1.6rem',
          lg: '1.8rem',
          xl: '2.4rem',
          xxl: '3.2rem',
          xxxl: '4rem',
          xxxxl: '5.6rem',
        },
      },
      fontWeights: {
        regular: 400,
        semiBold: 500,
        bold: 600,
        black: 900,
      },
    },
  },
};

Text.propTypes = {
  color: PropTypes.string,
  ellipsis: PropTypes.bool,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  align: PropTypes.string,
  lineHeight: PropTypes.string,
  textTransform: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object,
};

export default Text;
