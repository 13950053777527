import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.input`
    width: 100%;
    padding: 1.5rem;
    font-size: 1.4rem;
    border: 1px solid #dedede;
    background-color: #f7f7f7;
    color: #707070;
    border-radius: 4px;

    ${({ disabled }) => disabled
      && `
        color: #BBBBBB;
      `}

      ${({ multiline }) => !multiline
      && `
        height: 5.6rem;
      `}
`;

const TextField = (props) => {
  const { multiline, rows, ...rest } = props;

  return (
    <Root
      as={multiline && 'textarea'}
      multiline={multiline}
      {...(multiline && { rows })}
      {...rest}
    />
  );
};

TextField.defaultProps = {
  multiline: false,
  rows: '4'
};

TextField.propTypes = {
  multiline: PropTypes.bool,
  rows: PropTypes.string,
};

export default TextField;
