import { useState } from 'react';
import styled from 'styled-components';
import Padded from '../../components/padded/Padded';
import FeatureTab from './FeatureTab';
import FeatureTabPanel from './FeatureTabPanel';

const tabs = [
  {
    label: 'დოკუმენტის შექმნა',
    value: 'createDocument',

  },
  {
    label: 'ხელმოწერა',
    value: 'createSignature',
  },
  {
    label: 'საქაღალდეების მართვა',
    value: 'menageFolders',
  },
  {
    label: 'საქმიანობის მონიტორინგი',
    value: 'monitoring',
  },
  {
    label: 'ვიდეო ზარები',
    value: 'videoCalls',

  },
  {
    label: 'შაბლონები',
    value: 'templates',

  },
  {
    label: 'სხვა ფუნქციონალი',
    value: 'other',

  }
];

const featuresMap = new Map([
  ['createDocument', { description: 'დოკუმენტის შექმნა, ფუნქციონალის აღწერა და რისთვის არის საჭირო, რა ძირითად მოთხოვნებს დააკმაყოფილებს მისი გამოყენება და ასე შემდეგ, ტექსტი უნდა ჩაეტიოს ორ ხაზში',
    image: '/static/features/select-document.jpg' }
  ],
  ['createSignature', { description: 'ხელმოწერა, ფუნქციონალის აღწერა და რისთვის არის საჭირო, რა ძირითად მოთხოვნებს დააკმაყოფილებს მისი გამოყენება და ასე შემდეგ, ტექსტი უნდა ჩაეტიოს ორ ხაზში',
    image: '/static/features/select-document.jpg' }
  ],
  ['menageFolders', { description: 'საქაღალდეების მართვა, ფუნქციონალის აღწერა და რისთვის არის საჭირო, რა ძირითად მოთხოვნებს დააკმაყოფილებს მისი გამოყენება და ასე შემდეგ, ტექსტი უნდა ჩაეტიოს ორ ხაზში',
    image: '/static/features/select-document.jpg' }
  ],
  ['monitoring', { description: 'საქმიანობის მონიტორინგი, ფუნქციონალის აღწერა და რისთვის არის საჭირო, რა ძირითად მოთხოვნებს დააკმაყოფილებს მისი გამოყენება და ასე შემდეგ, ტექსტი უნდა ჩაეტიოს ორ ხაზში',
    image: '/static/features/select-document.jpg' }
  ],
  ['videoCalls', { description: 'ვიდეო ზარები, ფუნქციონალის აღწერა და რისთვის არის საჭირო, რა ძირითად მოთხოვნებს დააკმაყოფილებს მისი გამოყენება და ასე შემდეგ, ტექსტი უნდა ჩაეტიოს ორ ხაზში',
    image: '/static/features/select-document.jpg' }
  ],
  ['templates', { description: 'შაბლონები, ფუნქციონალის აღწერა და რისთვის არის საჭირო, რა ძირითად მოთხოვნებს დააკმაყოფილებს მისი გამოყენება და ასე შემდეგ, ტექსტი უნდა ჩაეტიოს ორ ხაზში',
    image: '/static/features/select-document.jpg' }
  ],
  ['other', { description: 'სხვა ფუნქციონალი, ფუნქციონალის აღწერა და რისთვის არის საჭირო, რა ძირითად მოთხოვნებს დააკმაყოფილებს მისი გამოყენება და ასე შემდეგ, ტექსტი უნდა ჩაეტიოს ორ ხაზში',
    image: '/static/features/select-document.jpg' }
  ]
]);

const Root = styled.ul`
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
`;

const Tabs = styled.ul`
    @media only screen and (max-width: 768px) {
      display: flex;
      overflow-x: auto;
      --gap-from-vieport: calc((100vw - 100%) / 2);
      margin-left: calc(-1 * var(--gap-from-vieport));
      margin-right: calc(-1 * var(--gap-from-vieport));
      padding: 0 var(--gap-from-vieport) 2rem;
      gap: 1rem;
    }
`;

const FeaturessTabs = () => {
  const [currentTab, setCurrentTab] = useState('createDocument');

  const handleChangeTab = (value) => {
    setCurrentTab(value);
  };

  return (
    <Root>
      <Padded
        top
        bottom
        size="4rem"
        style={{ zIndex: 1 }}
        media={{
          768: {
            top: {
              size: '0rem'
            },
            bottom: {
              size: '4rem'
            }
          }
        }}
      >
        <Tabs>
          {tabs.map((tab) => (
            <FeatureTab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              description={tab.description}
              image={tab.image}
              onClick={handleChangeTab}
              current={currentTab === tab.value}
            />
          ))}
        </Tabs>
      </Padded>
      <FeatureTabPanel
        description={featuresMap.get(currentTab).description}
        image={featuresMap.get(currentTab).image}
      />
    </Root>
  );
};

export default FeaturessTabs;
