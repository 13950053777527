import styled from 'styled-components';
import Container from '../../components/container/Container';
import Text from '../../components/text/Text';
import Flex from '../../components/flex/Flex';
import Padded from '../../components/padded/Padded';
import Button from '../../components/button/Button';

const Root = styled.section`
  padding: 12rem 0;

  @media only screen and (max-width: 768px) {
    padding: 4rem 0;
  }
`;

const Ilustration = styled.img`
  width: 47rem;
  max-width: 100%;

  @media only screen and (max-width: 768px) {
    display: none;
  }

  ${({ isMobile }) => isMobile
    && `
      display: none;

      @media only screen and (max-width: 768px) {
        margin: 2rem auto;
        
        display: block;
      }
    `}
`;

const AuthButtonsWrapper = styled.div`

`;

const Hero = () => (
  <Root>
    <Container>
      <Flex alignItems="center">
        <Padded
          right
          size="2.5rem"
          media={{
            768: {
              right: {
                size: 0
              }
            }
          }}
        >
          <Text
            as="h3"
            fontSize="xxl"
            textTransform="uppercase"
            lineHeight="1.6"
            fontWeight="bold"
          >
            ციფრული პლატფორმა, სადაც შეგიძლია
            {' '}
            <Text
              as="span"
              color="primary"
              fontSize="xxl"
              fontWeight="bold"
            >
              ხელმოწერა
            </Text>
          </Text>

          <Ilustration
            src="/static/ilustrations/contract-signing.png"
            alt="Contract Signing"
            isMobile
          />

          <Padded
            top
            size="2.5rem"
          >
            <Text lineHeight="1.6">
              პლატფორმის მოკლე მიმოხილვა, რისთვის არის ის და ვის შეუძლია
              გამოიყენოს პლატფორმის ფუნქციონალი. ტექსტი შეიძლება დაიწეროს
              რამოდენიმე წინადადებით
            </Text>
          </Padded>

          <AuthButtonsWrapper>
            <Padded
              top
              size="6rem"
            >
              <Flex>
                <Button
                  as="a"
                  href="http://app.ecosign.ge/"
                >
                  ჩაერთე უფასოდ
                </Button>

                {false && (
                <Padded
                  left
                  size="2.4rem"
                >
                  <Button variant="outlined">ნახეთ ვიდეო</Button>
                </Padded>
                )}
              </Flex>
            </Padded>
          </AuthButtonsWrapper>
        </Padded>

        <Ilustration
          src="/static/ilustrations/contract-signing.png"
          alt="Contract Signing"
        />
      </Flex>
    </Container>
  </Root>
);

export default Hero;
