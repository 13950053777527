import styled from 'styled-components';
import PropTypes from 'prop-types';
import Padded from '../../components/padded/Padded';
import Text from '../../components/text/Text';

const Root = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 4.5rem;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-left: -2rem;
  margin-right: -2rem;

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }

  > p {
    @media only screen and (max-width: 768px) {
      order: 1;
    }
  }

  img {
    @media only screen and (max-width: 768px) {
      filter: drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.16));
    }
  }
`;

const FeatureTabPanel = (props) => {
  const { description, image, ...rest } = props;

  return (
    <Root {...rest}>
      <Text lineHeight="1.6">{description}</Text>

      <Padded
        top
        size="2.4rem"
        media={{
          768: {
            top: {
              size: 0
            },
            bottom: {
              size: '1.5rem'
            }
          }
        }}
      >
        <img
          src={image}
          alt="Select Document"
          style={{ width: '100%' }}
        />
      </Padded>
    </Root>
  );
};

FeatureTabPanel.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
};

export default FeatureTabPanel;
