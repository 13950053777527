import { useEffect } from 'react';
import PrivacyPolicyModule from '../../modules/privacy-policy/PrivacyPolicyModule';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.documentElement.scrollTo(0, 0);
  }, []);

  return <PrivacyPolicyModule />;
};

export default PrivacyPolicy;
