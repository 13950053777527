import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Padded = styled.div`
  padding-top: ${({ size, top }) => top && size};
  padding-right: ${({ size, right }) => right && size};
  padding-bottom: ${({ size, bottom }) => bottom && size};
  padding-left: ${({ size, left }) => left && size};

  ${({ media }) => {
    if (media && media[768]) {
      return css`
        @media only screen and (max-width: 768px) {
          padding-top: ${({ media: { 768: { top } } }) => top && top.size};
          padding-right: ${({ media: { 768: { right } } }) => right && right.size};
          padding-bottom: ${({ media: { 768: { bottom } } }) => bottom && bottom.size};
          padding-left: ${({ media: { 768: { left } } }) => left && left.size};
        }
      `;
    }

    return css``;
  }}
`;

Padded.defaultProps = {
  bottom: false,
  left: false,
  right: false,
  top: false,
  size: '2rem',
  media: {},
};

Padded.propTypes = {
  bottom: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  size: PropTypes.string,
  top: PropTypes.bool,
  media: PropTypes.object,
};

export default Padded;
