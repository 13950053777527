import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * { 
      box-sizing: border-box;
      outline-color: #dedede;
      margin: 0;
      padding: 0;
  }

  html, body { -webkit-font-smoothing: antialiased; }
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;

    @media only screen and (max-width: 1280px) {
      font-size: 58%;
    }

    @media only screen and (max-width: 1080px) {
      font-size: 40%;
    }

    @media only screen and (max-width: 768px) {
      font-size: 50%;
    }

  }
  body {
      font-size: 1.6rem;
  }
  p {
    margin: 0;
  }
  a, button {
    cursor: pointer;
    outline: 0;
  }
  h1, h2, h3, p, label, button, input, textarea, a, span {
    /* stylelint-disable */
    font-family: 'Helvetica' !important;
    /* styleint-enable */
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a {
    text-decoration: none;
  }

  #nprogress .bar {
    background: #18B290 !important;
  }
  
  #nprogress .peg {
    box-shadow: 0 0 10px #18B290, 0 0 5px #18B290;
  }
  
  #nprogress .spinner-icon {
    border-top-color: #18B290;
    border-left-color: #18B290;
  }

  svg {
    width: 2rem;
    color: #707070;
  }

  .logo-image {
    width: 13rem;
  }
`;

export default GlobalStyle;
