import styled from 'styled-components';
import Container from '../../components/container/Container';
import Padded from '../../components/padded/Padded';
import Flex from '../../components/flex/Flex';
import Button from '../../components/button/Button';
import Text from '../../components/text/Text';
import TextField from '../../components/text-field/TextField';

const Root = styled.section`
    padding: 8rem 0;

    @media only screen and (max-width: 768px) {
      padding: 6.4rem 0;
    }
`;

const Subscribe = () => (
  <Root id="contact">
    <Container>
      <header>
        <Text
          as="h3"
          color="black"
          textTransform="uppercase"
          fontSize="xl"
          align="center"
          fontWeight="bold"
          media={{
            768: {
              align: 'left'
            }
          }}
        >
          ვერ მიიღეთ საკმარისი ინფორმაცია?
        </Text>
        <Padded
          top
          size="2.4rem"
        >
          <Text
            lineHeight="1.6"
            align="center"
            media={{
              768: {
                align: 'left'
              }
            }}
          >
            შექმენი შენი ხელმოწერა ახლავე. ხელმოწერის გენერირებაის მეთოდებზე
            <br />
            მოკლედ და პროცესის სღწერა ძალიან მოკლედ
          </Text>
        </Padded>
      </header>

      <Padded
        top
        size="4rem"
      >
        <Flex
          justifyContent="center"
          media={{
            768: {
              justifyContent: 'flex-start'
            }
          }}
        >
          <Flex style={{ width: '40rem' }}>
            <TextField placeholder="მიუთითეთ ელ-ფოსტა" />
            <Button style={{ marginLeft: '-0.5rem' }}>გაგზავნა</Button>
          </Flex>
        </Flex>
      </Padded>

    </Container>
  </Root>
);

export default Subscribe;
