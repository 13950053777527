import styled from 'styled-components';
import PropTypes from 'prop-types';
import Text from '../../components/text/Text';
import Padded from '../../components/padded/Padded';

const Root = styled.div`
    height: 17rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 3.5rem;
    background: #FFFFFF;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    @media only screen and (max-width: 768px) {
      height: auto;
      flex-flow: row;
      justify-content: flex-start;
    padding: 2.5rem; 3rem;
    }

    img {
      @media only screen and (max-width: 768px) {
        width: 5rem !important;
      }
    }
`;

const Certificate = (props) => {
  const { title, image, ...rest } = props;

  return (
    <Root {...rest}>

      <img
        src={image}
        alt="Company"
        style={{ width: '10rem' }}
      />

      <Padded
        top
        size="1.5rem"
        media={{
          768: {
            top: {
              size: 0
            },
            left: {
              size: '1rem'
            }
          }
        }}
      >
        <Text
          color="black"
          fontSize="sm"
          textTransform="uppercase"
          align="center"
          lineHeight="1.6"
          media={{
            768: {
              fontSize: 'xl',
            }
          }}
        >
          {title}
        </Text>
      </Padded>

    </Root>
  );
};

Certificate.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
};

export default Certificate;
