/* eslint-disable no-unused-vars */
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from './Navbar';
import Footer from './Footer';

const Root = styled.div`
`;

const MainLayout = () => (
  <Root>
    <Navbar />
    <Outlet />
    <Footer />
  </Root>
);

export default MainLayout;
