/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import Container from '../../components/container/Container';
import Hr from '../../components/hr/Hr';
import Hero from './Hero';
import Features from './Features';
import Statistics from './Statistics';
import Secure from './Secure';
import Ecology from './Ecology';
import Subscribe from './Subscribe';
import Pricing from './Pricing';

const Root = styled.main``;
const HrWrapper = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const LoginModule = () => (
  <Root>
    <Hero />
    <Features />
    <Statistics />
    <Secure />
    <Ecology />
    <Pricing />
    <HrWrapper>
      <Container>
        <Hr />
      </Container>
    </HrWrapper>
    <Subscribe />
  </Root>
);

export default LoginModule;
