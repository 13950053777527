import { createGlobalStyle } from 'styled-components';

/* Helvetica Regular */
import helveticaRegular from '../assets/fonts/helvetica/HelveticaNeueLTGEOW82-55Rm.ttf';
import helveticaRegularWoff from '../assets/fonts/helvetica/HelveticaNeueLTGEOW82-55Rm.woff';
import helveticaRegularWoff2 from '../assets/fonts/helvetica/HelveticaNeueLTGEOW82-55Rm.woff2';

const Font = createGlobalStyle`

/* Helvetica Regular - 400 */

@font-face {
  font-family: 'Helvetica';
  src: url(${helveticaRegularWoff2}) format('woff2'), url(${helveticaRegularWoff}) format('woff'), url(${helveticaRegular}) format('truetype');
  font-weight: 400;
  font-style: normal;
};`;

export default Font;
