import styled from 'styled-components';
import Container from '../../components/container/Container';
import Text from '../../components/text/Text';
import Padded from '../../components/padded/Padded';
import PricingPlan from './PricingPlan';

const Root = styled.section`
  padding: 8rem 0;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    padding: 6.4rem 0;
    background-color: #f7f7f7;
  }
`;

const PricesContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 768px) {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 50%;

    overflow-x: auto;
    --gap-from-vieport: calc((100vw - 100%) / 2);
    margin-left: calc(-1 * var(--gap-from-vieport));
    margin-right: calc(-1 * var(--gap-from-vieport));
    padding: 0 var(--gap-from-vieport) 2rem;
  }

  @media only screen and (max-width: 640px) {
    grid-auto-columns: 70%;
  }
  
  @media only screen and (max-width: 440px) {
    grid-auto-columns: 80%;
  }
`;

const Secure = () => (
  <Root id="pricing">
    <Container>
      <header>
        <Text
          as="h3"
          color="black"
          textTransform="uppercase"
          fontSize="xl"
          align="center"
          fontWeight="bold"
          media={{
            768: {
              align: 'left',
            },
          }}
        >
          შეარჩიეთ თქვენზე მორგებული პაკეტი
        </Text>
        <Padded
          top
          size="2.4rem"
        >
          <Text
            lineHeight="1.6"
            align="center"
            media={{
              768: {
                align: 'left',
              },
            }}
          >
            შეადარეთ და აირჩიეთ თქვენს საჭიროებებზე მორგებული პაკეტი. შეძენილი
            <br />
            პაკეტის გაუქმება შესაძლებელი იქნება ნებისმიერ მომენტში
          </Text>
        </Padded>
      </header>

      <Padded
        top
        size="4rem"
      >
        <PricesContainer>
          <PricingPlan
            name="უფასო"
            price="0"
            cta="ყიდვა"
            features={[
              [1, 'დოკუმენტი თვეში'],
              ['1 GB', 'ონლაინ სივრცე'],
              [1, 'ონლაინ შეხვედრა'],
              [1, 'კომპანიის დამატება'],
              [3, 'თანამშრომელი'],
            ]}
          />
          <PricingPlan
            name="სტანდარტი"
            price="100"
            cta="ყიდვა"
            features={[
              [10, 'დოკუმენტი თვეში'],
              ['5 GB', 'ონლაინ სივრცე'],
              [10, 'ონლაინ შეხვედრა'],
              [3, 'კომპანიის დამატება'],
              [2, 'თანამშრომელი'],
            ]}
          />
          <PricingPlan
            name="პრემიუმი"
            price="300"
            cta="ყიდვა"
            features={[
              [100, 'დოკუმენტი თვეში'],
              ['30 GB', 'ონლაინ სივრცე'],
              ['ულიმიტო', 'ონლაინ შეხვედრა'],
              ['ულიმიტო', 'კომპანიის დამატება'],
              ['ულიმიტო', 'თანამშრომელი'],
            ]}
          />
          <PricingPlan
            name="ენტერპრაისი"
            price="300+"
            cta="კონტაქტი"
            features={[
              ['ულიმიტო', 'დოკუმენტი თვეში'],
              ['ულიმიტო', 'ონლაინ სივრცე'],
              ['ულიმიტო', 'ონლაინ შეხვედრა'],
              ['ულიმიტო', 'კომპანიის დამატება'],
              ['ულიმიტო', 'თანამშრომელი'],
            ]}
          />
        </PricesContainer>
      </Padded>
    </Container>
  </Root>
);

export default Secure;
