import styled from 'styled-components';
import Container from '../../components/container/Container';
import Flex from '../../components/flex/Flex';
import Padded from '../../components/padded/Padded';
import Text from '../../components/text/Text';
import Hr from '../../components/hr/Hr';
import { ReactComponent as MapPinIcon } from '../../icons/map-pin.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as MailIcon } from '../../icons/mail.svg';
import FooterSocialMedia from './FooterSocialMedia';
import { HashLink as Link } from 'react-router-hash-link';

const sections = [
  {
    title: 'პროდუქტი',
    items: [
      {
        title: 'პაკეტები',
        path: '/#pricing',
      },
      {
        title: 'სერთიფიკატები',
        path: '/#certificates',
      },
      {
        title: 'დახმარება',
        path: '/#contact',
      },
    ],
  },
  {
    title: 'ინფორმაცია',
    items: [
      {
        title: 'წესები და პირობები',
        path: '/privacy-policy',
      },
      {
        title: 'კონფიდენციალურობა',
        path: '/terms-and-conditions',
      },
    ],
  },
  {
    title: 'კონტაქტი',
    items: [
      {
        title: 'თბილისი, ქუჩის დასახელება #24',
        icon: <MapPinIcon />,
      },
      {
        title: '+995 032 2 12 34 56',
        icon: <PhoneIcon />,
      },
      {
        title: 'info@ecosign.ge',
        icon: <MailIcon />,
      },
    ],
  },
];

const Root = styled.footer`
  background-color: #efefef;
  padding-top: 8rem;
  padding-bottom: 4rem;

  @media only screen and (max-width: 768px) {
    padding-top: 4.4rem;
  }
`;

const SectionItems = styled.ul`
  > li:not(:first-child) {
    margin-top: 2rem;
    @media only screen and (max-width: 768px) {
      margin-top: 2rem;
    }
  }
`;

const Wrapper = styled.div`

  &:not(:first-child) {
    @media only screen and (max-width: 768px) {
      margin-top: 4rem;
    }
  }
`;

const Footer = () => (
  <Root>
    <Container>
      <Flex
        justifyContent="space-between"
        media={{
          768: {
            flexDirection: 'column',
          },
        }}
      >
        {sections.map((section) => (
          <Wrapper key={section.title}>
            <Text
              fontSize="xl"
              color="black"
              textTransform="uppercase"
            >
              {section.title}
            </Text>

            <Padded
              top
              size="4rem"
              media={{
                768: {
                  top: {
                    size: '2rem'
                  }
                }
              }}
            >
              <SectionItems>
                {section.items.map((item) => (
                  <li key={item.title}>
                    <Flex>
                      {item.icon && (
                        <Padded
                          right
                          size="1rem"
                        >
                          {item.icon}
                        </Padded>
                      )}
                      <Text {...(item.path && { to: item.path, as: Link })}>
                        {item.title}
                      </Text>
                    </Flex>
                  </li>
                ))}
              </SectionItems>
            </Padded>
          </Wrapper>
        ))}
        <FooterSocialMedia />
      </Flex>

      <Padded
        top
        bottom
        size="4rem"
      >
        <Hr />
      </Padded>

      <Flex>
        <Text>© 2022 EcoSign</Text>
      </Flex>
    </Container>
  </Root>
);

export default Footer;
