import styled from 'styled-components';
import Container from '../../components/container/Container';
import Padded from '../../components/padded/Padded';
import Text from '../../components/text/Text';
import FeaturesTabs from './FeaturesTabs';

const Root = styled.section`
    padding: 6.4rem 0;
    background-color: #F7F7F7;
    overflow: hidden;
`;

const Features = () => (
  <Root>
    <Container>
      <Text
        as="h3"
        color="black"
        textTransform="uppercase"
        fontSize="xl"
        lineHeight="1.6"
        align="center"
        fontWeight="bold"
        media={{
          768: {
            align: 'left'
          }
        }}
      >
        ონლაინ ხელმოწერის და დოკუმენტების სამართავი
        <br />
        ყველა ფუნქციონალი ერთ პლატფორმაზე
      </Text>

      <Padded
        top
        size="4rem"
      >
        <FeaturesTabs />
      </Padded>
    </Container>
  </Root>
);

export default Features;
