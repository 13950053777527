import styled from 'styled-components';
import PropTypes from 'prop-types';

const colors = {
  primary: {
    contained: {
      backgroundColor: '#18B290',
      color: '#fff'
    },
    outlined: {
      borderColor: '#18B290',
      color: '#18B290'
    },
    text: {
      color: '#18B290'
    }
  }
};

const Button = styled.button`
   /* General style */
   display: inline-flex;
   align-items: center;
   justify-content: center;
   font-feature-settings: 'case' on;
   border-radius: 4px;
   cursor: pointer;
   outline: 0;
   white-space: nowrap;
   width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

   &:focus {
     outline: 0;
   }
   
   
   ${({ disabled }) => disabled
     && `
     &:hover {
       box-shadow: none;
       cursor: initial;
     }
     `}

     ${({ size }) => `
       ${size === 'small' && `
          padding: 0 1.2rem;
          height: 4rem;
          font-size: 1.4rem;
       `};       

       ${size === 'medium' && `
          padding: 0 2.4rem;
          height: 5.5rem;
          font-size: 1.6rem;
       `};

       ${size === 'large' && `
           padding: 0 3.4rem;
           height: 6.5rem;
           font-size: 1.6rem;
        `};
     `}     

   /* Specific style */
   ${({ color, variant }) => color !== 'none'
     && `
       ${variant === 'contained' && `
          background-color: ${colors[color].contained.backgroundColor}; 
          border: none;
       `};

       ${variant === 'outlined' && `
          border: 1px solid ${colors[color].outlined.borderColor};
          background: none;
       `};

       ${variant === 'text' && `
          border: none;
          background: none;
        `};

       color: ${colors[color][variant].color};
     `}
     
 `;

Button.defaultProps = {
  color: 'primary',
  variant: 'contained',
  size: 'medium',
  disabled: false,
  type: 'button',
  fullWidth: false
};

Button.propTypes = {
  color: PropTypes.oneOf([
    'primary',
  ]),
  variant: PropTypes.oneOf([
    'contained',
    'outlined',
    'text',
  ]),
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large',
  ]),
  disabled: PropTypes.bool,
  type: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default Button;
